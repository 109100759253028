<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            placeholder="Select Date"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col md="4">
                        <b-form-group v-if="User == 'admin'">
                          <label>Type</label>
                          <v-select
                            @input="getEmployee($event)"
                            v-model="branchname"
                            placeholder="None"
                            label="name"
                            :options="branchOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="branchname && branchname.name !== 'All'">
                        <b-form-group>
                          <label
                            >Select
                            {{
                              branchname.name == "Admin" ? "Employee" : branchname.name
                            }}</label
                          >
                          <v-select
                            v-model="employee"
                            label="fullname"
                            placeholder="None"
                            :options="$store.getters['master/getUsers'](branchname.id)"
                          >
                            <template
                              #option="{ name, profile_image, username, surname }"
                            >
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder">
                                {{ name }} {{ surname }}</span
                              ><span>({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :columns="fields">
                <template #customField="{ props }">
                  <span v-if="props.column.field === 'managername'">
                    <div class="d-flex align-items-center" style="gap: 10px">
                      <div
                        v-if="
                          props.row.managername && props.row.managername.profile_image
                        "
                      >
                        <b-avatar
                          style="height: 40px; width: 40px"
                          :src="getprofileImage(props.row.managername.profile_image)"
                        />
                      </div>
                      <span v-if="props.row.managername">
                        {{ props.row.managername.fullname }}
                      </span>
                    </div>
                  </span>
                </template>
              </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import { BFormGroup, BForm, BRow, BCol, BButton, BAvatar } from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
    BAvatar,
  },
  data() {
    return {
      startdate: "",
      enddate: "",
      result: "",
      type: "",
      employee: "",
      employeeoption: [],
      subemployee: "",
      subemployeeoption: [],
      users: [],
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "managername",
          label: "Manager Name",
          image_field: "profile_image",
          dynamic: true,
          responseField: "fullname",
        },
        { field: "datetime", label: "Complaint Date" },
        { field: "type", label: "Type" },
        { field: "customer", label: "Customer Name" },
        { field: "mobile", label: "Mobile No" },
        { field: "address", label: "Address" },
        { field: "remarks", label: "Remarks" },
      ],
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Employee", id: "employee" },
        { name: "All", id: "all" },
      ],
      User: "",
      employee: "",
      branchname: "",
    };
  },
  mounted() {
    this.User = this.$store.state.app.user_data.role;
    if (this.User !== "admin") {
      this.getEmployee({ id: this.User });
    }
  },
  methods: {
    async getEmployee(e) {
      this.employee = "";
      if (e && e.id == "channelpartner") {
        this.$store.dispatch("master/getChannelpartner");
      } else if (e && e.id == "franchise") {
        this.$store.dispatch("master/getFranchise");
      } else if (e && e.id == "employee") {
        this.$store.dispatch("master/getEmployee");
      }
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData() {
      const data = {
        startdate: this.startdate,
        enddate: this.enddate,
        employee_id: this.employee ? this.employee.id : "",
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/complaintreport`,
      }).then((response) => {
        this.data = response.data.data;
        this.data.map((item) => {
          item.datetime = moment(item.datetime).format("DD/MM/YYYY");
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
